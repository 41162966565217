<template>
  <div class='card'>
    <div class='container'>
      <b-modal ref='addFloorVehicleAttributeStoresModal' hide-footer>
        <addFloorVehicleAttributeStores @closeAddFloorVehicleAttributeStores='toggleAddFloorVehicleAttributeStores()' @addFloorVehicleAttributeStoresSuccess='addFloorVehicleAttributeStoresSuccess()'></addFloorVehicleAttributeStores>
      </b-modal>
      <b-modal ref='editFloorVehicleAttributeStoresModal' hide-footer>
        <editFloorVehicleAttributeStores
          :editingFloorVehicleAttributeStores='currentFloorVehicleAttributeStores'
          @closeEditFloorVehicleAttributeStores='toggleEditFloorVehicleAttributeStores()'
          @editFloorVehicleAttributeStoresSuccess='editFloorVehicleAttributeStoresSuccess()'
        ></editFloorVehicleAttributeStores>
      </b-modal>
      <div class='row'>
        <h2>FloorVehicleAttributeStores</h2>
      </div>
      <div class='row'>
        <button @click='toggleAddFloorVehicleAttributeStores()' type='button' class='btn btn-success'>Add</button>
      </div>
      <div class='row'>
        <br>
        <datatable :dataItems='items' @rowClicked='editItem' :dataColumns='columns' :searching='true' :sorting='true' @editRow='editItem' :order='[1, "dec"]' class='text-center' striped bordered />
      </div>
    </div>
  </div>
</template>
<script>
import addFloorVehicleAttributeStores from '../../components/addFloorVehicleAttributeStores';
import editFloorVehicleAttributeStores from '../../components/editFloorVehicleAttributeStores';
import VueNotifications from 'vue-notifications';
import {    Datatable  } from '../../mdb/components/Datatable';
import miniToastr from 'mini-toastr'; // https://github.com/se-panfilov/mini-toastr
const toastTypes = {
  success: 'success',
  error: 'error',
  info: 'info',
  warn: 'warn'
};
miniToastr.init({ types: toastTypes });
export default {
  data() {
    return {
      columns: [
              {
                label: 'Id',
                field: 'Id',
                sort: 'asc',
              },
              {
                label: 'GroupSet',
                field: 'GroupSet',
                sort: 'asc',
              },
              {
                label: 'Categories',
                field: 'Categories',
                sort: 'asc',
              },
              {
                label: 'AttributeName',
                field: 'AttributeName',
                sort: 'asc',
              },
              {
                label: 'Value',
                field: 'Value',
                sort: 'asc',
              },
              {
                label: 'ValueType',
                field: 'ValueType',
                sort: 'asc',
              },
              {
                label: 'FK',
                field: 'FK',
                sort: 'asc',
              },
              {
                label: 'LastUpdated',
                field: 'LastUpdated',
                sort: 'asc',
              },
      ],
      items: [],
      addFloorVehicleAttributeStoresOpen: false,
      editFloorVehicleAttributeStoresOpen: false,
      currentFloorVehicleAttributeStores: {}
    };
  },
  components: {
    addFloorVehicleAttributeStores,
    editFloorVehicleAttributeStores,
    Datatable,
  },
  created: function() {
    this.getFloorVehicleAttributeStores();
    this.addFloorVehicleAttributeStoresOpen = false;
    this.editFloorVehicleAttributeStoresOpen = false;
  },
  methods: {
    getImageSource(data) {
      if (!this.isNull(data) && !this.isNull(data.Image)) {
        return this.$store.state.cdnUrl + data.Image;
      }
      return '';
    },
    isNull: function(obj) {
      if (typeof obj === 'undefined' || obj === null || obj === 'null') {
        return true;
      }
      return false;
    },
 getFloorVehicleAttributeStores() {
      var payload = {
        success: response => {
          this.items = response.data;
        },
        error: error => { miniToastr['error'](error, 'Error', 1000, null)}
      }
      this.$store.dispatch('getFloorVehicleAttributeStores', payload);
    },
    editItem(FloorVehicleAttributeStores) {
      this.toggleEditFloorVehicleAttributeStores();
      this.currentFloorVehicleAttributeStores = FloorVehicleAttributeStores;
    },
    toggleAddFloorVehicleAttributeStores() {
      if(this.addFloorVehicleAttributeStoresOpen)
      {
        this.$refs.addFloorVehicleAttributeStoresModal.hide()
      }
      else{
        this.$refs.addFloorVehicleAttributeStoresModal.show()
      }
      this.addFloorVehicleAttributeStoresOpen = !this.addFloorVehicleAttributeStoresOpen;
    },
    addFloorVehicleAttributeStoresSuccess() {
      this.toggleAddFloorVehicleAttributeStores();
      miniToastr['success']('FloorVehicleAttributeStores Added', 'Success', 1000, null);
      this.getFloorVehicleAttributeStores();
    },
    toggleEditFloorVehicleAttributeStores() {
      if(this.editFloorVehicleAttributeStoresOpen)
      {
        this.$refs.editFloorVehicleAttributeStoresModal.hide()
      }
      else{
        this.$refs.editFloorVehicleAttributeStoresModal.show()
      }
      this.editFloorVehicleAttributeStoresOpen = !this.editFloorVehicleAttributeStoresOpen;
    },
    editFloorVehicleAttributeStoresSuccess() {
      this.toggleEditFloorVehicleAttributeStores();
      miniToastr['success']('FloorVehicleAttributeStores Edited', 'Success', 1000, null);
      this.getFloorVehicleAttributeStores();
    }
  }
};
</script>
<style scoped>
.FloorVehicleAttributeStoresThumbnail {
  height: 50px;
}
</style>
